// import React from 'react';
import { CssBaseline, Container, AppBar, Tabs, Tab, makeStyles, createStyles, Typography, Toolbar } from '@material-ui/core'
import EbayEvents from './components/ebayevents'

const useStyles = makeStyles(theme => createStyles({
  root: {
    marginTop: theme.spacing(16),
  },
  title: {
    marginRight: theme.spacing(4),
  },
  appBar: {
    // paddingLeft: theme.spacing(4),
    // paddingRight: theme.spacing(4),
    '& > .MuiToolbar-root': {
      minHeight: 'auto',
    }
  }
}))

function App() {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography
            className={classes.title}
            variant="h5"
          >
            Offers & Discounts
          </Typography>
          <Tabs value={0}>
            <Tab label="eBay" />
            <Tab label="more coming..." disabled />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <EbayEvents />
      </Container>
    </>
  )
}

export default App
