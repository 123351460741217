import React, { useEffect, useState } from 'react'
import { Box, Container, createStyles, Divider, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core'
import EventCard, { EventData, EventParams } from './eventcard'
import axios, { AxiosResponse } from 'axios'

const sites = ['AU', 'CA', 'DE', 'ES', 'FR', 'IE', 'IT', 'UK', 'US']
// 简单的用语言决定用户当前所在国家
const userSite = (window.navigator.language.split('-')[1] || '').toUpperCase()

const useStyles = makeStyles(theme => createStyles({
  root: {
    paddingTop: theme.spacing(0),
    // height: '100%',
  },
  toolbar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    position: 'fixed',
    top: theme.spacing(7),
    left: '50%',
    transform: 'translateX(-50%)',
    minHeight: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: '4px',
  },
  siteLabel: {
    marginRight: theme.spacing(2),
  },
  // cards: {
  //   paddingTop: theme.spacing(9),
  //   height: '100%',
  //   // overflow: 'auto',
  //   // '& > .MuiGrid-container': {
  //   //   height: '100%',
  //   //   overflow: 'auto',
  //   //   paddingTop: theme.spacing(1),
  //   // }
  // },
  select: {
    minWidth: theme.spacing(16),
    marginRight: theme.spacing(3),
  },
}))

interface EventInfo {
  params: EventParams
  data: EventData[]
}

const EbayEvents: React.FC = () => {
  const classes = useStyles()
  const [events, setEvents] = useState<EventInfo | null>(null)
  const [site, setSite] = useState(sites.includes(userSite) ? userSite : 'US')
  const [category, setCategory] = useState('All')
  const [categories, setCategories] = useState<string[]>([])
  const [cards, setCards] = useState<EventData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get<any, AxiosResponse<EventInfo>>(`/data/ebay/events/${site.toLowerCase()}.json`)
        setEvents(data)
        const groupNames = new Set(data.data.map(({ groupName }) => groupName))
        setCategory('All')
        setCategories(Array.from(groupNames))
      } catch {
        setEvents(null)
        setCategory('All')
        setCategories([])
      }
    }

    fetchData()
  }, [site])

  // 过滤数据
  useEffect(() => {
    if (events === null) {
      setCards([])
    } else {
      const data = events.data.filter(d => d.groupName === category || category === 'All')
      setCards(data)
    }
  }, [events, category])

  return (
    <Box position="relative" className={classes.root}>
      <Grid container spacing={2}>
        {events ? cards.map((ev) => (
          <Grid container item key={ev.id} xs={4} justify="center">
            <EventCard params={events.params} data={ev} />
          </Grid>
        )) : null}
      </Grid>
      <Container className={classes.toolbar} maxWidth="sm">
        <TextField
          select
          label="Site"
          value={site}
          onChange={e => setSite(e.target.value)}
          className={classes.select}
          variant="outlined"
          size="small"
        >
          {sites.map(site => (
            <MenuItem key={site} value={site}>{site}</MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Category"
          value={category}
          className={classes.select}
          onChange={e => setCategory(e.target.value)}
          variant="outlined"
          size="small"
        >
          <MenuItem value="All">All</MenuItem>
          <Divider />
          {categories.map(cat => (
            <MenuItem key={cat} value={cat}>{cat}</MenuItem>
          ))}
        </TextField>
      </Container>
    </Box>
  )
}

export default EbayEvents
