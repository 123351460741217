import React from 'react'
import { Button, Card, CardActionArea, CardContent, CardMedia, Chip, createStyles, Link, makeStyles, Typography } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

export interface EventData {
  id: string
  url: string
  imageUrl: string
  startDate: string
  endDate: string
  name: string
  description: string
  color: string
  background: string
  groupName: string
}

export interface EventParams {
  mkcid: string
  mkrid: string
  siteid: string
  toolid: string
  customid: string
  mkevt: string
}

export interface EventCardProps {
  params: EventParams
  data: EventData
}

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'block',
    maxWidth: '200px',
    '& .MuiChip-root': {
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
  name: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.2,
  },
  link: {
    // textDecoration: 'none !important',
  },
  group: {
    height: 'auto',
    maxWidth: '100%',
    '& > .MuiChip-label': {
      whiteSpace: 'break-spaces',
    },
  },
  img: {
    width: '100%',
  },
  btn: {
    width: '100%',
    margin: `${theme.spacing(1.5)}px auto`,
    textAlign: 'center',
  }
}))

/**
 * 最终ur示例  
 * US:
 * https://www.ebay.com/e/b&i/office-organization-071819?mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338808721&toolid=20014&customid=&mkevt=1
 * https://www.ebay.com/e/fashion/iwjg?mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338808721&toolid=20014&customid=&mkevt=1
 * 
 * AU:
 * https://www.ebay.com.au/e/_health-beauty/c-beauty-essentials?mkcid=1&mkrid=705-53470-19255-0&siteid=15&campid=5338808721&toolid=20014&customid=&mkevt=1
 * https://www.ebay.com.au/e/fashion/c-plus-mens-fashion?mkcid=1&mkrid=705-53470-19255-0&siteid=15&campid=5338808721&toolid=20014&customid=&mkevt=1
 */

/**
 * 网页广告的id标识
 */
const campaignId = 5338808721

/**
 * 
 * eBay折扣
 */
export default function EventCard({ data, params }: EventCardProps) {
  const cls = useStyles()
  const paramsStr = Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&')
  const href = `${data.url}?campid=${campaignId}&${paramsStr}`
  const endDate = new Date(data.endDate)
  const expires = `Expires ${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()}`
  const colors: React.CSSProperties = {
    color: data.color,
    borderColor: data.color,
  }

  return (
    <Card className={cls.root} style={{ background: data.background }}>
      <CardActionArea>
        <CardMedia>
          <Link href={href} target="_blank">
            <img src={data.imageUrl} alt="" className={cls.img} />
          </Link>
        </CardMedia>
      </CardActionArea>
      <CardContent>
        <Chip
          size="small"
          label={data.groupName}
          variant="outlined"
          className={cls.group}
          style={{ ...colors }}
        />
        <Link href={href} style={{ ...colors }} className={cls.link} target="_blank">
          <Typography variant="h5" className={cls.name}>
            {data.name}
          </Typography>
          <Typography variant="body2">
            {data.description}
          </Typography>
        </Link>
        <Button
          variant="contained"
          size="large"
          className={cls.btn}
          style={{ ...colors, backgroundColor: 'transparent', }}
          href={href}
          target="_blank"
          startIcon={<ShoppingCartOutlinedIcon />}
        >
          shopping
        </Button>
        <Typography variant="body2" style={{ ...colors, fontWeight: 'bold' }}>
          {expires}
        </Typography>
      </CardContent>
    </Card>
  )
}